<template>
    <div class="container is-max-widescreen mod-ribbon company-create">
        <div class="columns">
            <div class="column">
                <h1>Opret din Medvirker-profil</h1>
                <p>Bliv fundet af virksomheder, der mangler dine kompetencer.</p>
                <form class="steps">
                    <div v-if="global.companyCreate.step == 1" class="step step-1">
                        <h2>1: Opret login</h2>
                        <h3>Login med</h3>
                        <div class="login-sso">

                            <div class="field has-addons">
                                <p v-if="false" class="control">
                                    <button class="button" @click="openOauth('LinkedIn')">
                                        <span class="icon is-small">
                                            <i class="fab fa-linkedin"></i>
                                        </span>
                                        <span>LinkedIn</span>
                                    </button>
                                </p>
                                <p class="control">
                                    <button class="button" @click="openOauth('Google')">
                                        <span class="icon is-small">
                                            <i class="fab fa-google"></i>
                                        </span>
                                        <span>Google</span>
                                    </button>
                                </p>
                                <p class="control">
                                    <button class="button" @click="openOauth('Facebook')">
                                        <span class="icon is-small">
                                            <i class="fab fa-facebook"></i>
                                        </span>
                                        <span>Facebook</span>
                                    </button>
                                </p>
                            </div>
                        </div>
                        <h3>Eller med e-mail</h3>
                        <div class="login-email">
                            <div class="field">
                                <label for="global.companyCreate.data.user.email" class="is-sr-only">E-mail *</label>
                                <div class="control">
                                    <input id="global.companyCreate.data.user.email" class="input" type="text" placeholder="E-mail *" required 
                                        v-model="global.companyCreate.data.user.email"
                                        @input="isEmailAvailable(); validateStep();"
                                        :disabled="global.companyCreate.data.user.providerType == 'sso' ? true : false"
                                    >
                                    <p v-if="global.companyCreate.data.user.provider">Du er logget på med: {{ global.companyCreate.data.user.provider }}</p>
                                    <p v-if="!global.companyCreate.data.user.emailAvailable" class="has-text-danger">E-mail er allerede i brug på medvirke.dk</p>
                                </div>
                            </div>
                            <div v-if="isValidEmail(global.companyCreate.data.user.email) && global.companyCreate.data.user.providerType != 'sso'">
                                <div class="field">
                                    <label for="global.companyCreate.data.user.password" class="is-sr-only">Adgangskode *</label>
                                    <div class="control has-icons-right">
                                        <input id="global.companyCreate.data.user.password" class="input" type="password" placeholder="Adgangskode *" required autocomplete="off"
                                            v-model="global.companyCreate.data.user.password"
                                            @input="validateStep()"
                                        >
                                        <span class="icon is-small is-right is-hidden" ref="passwordCheckmark">
                                            <i class="fas fa-check"></i>
                                        </span>
                                    </div>
                                    <small>Adgangskoden skal indeholde mindst 8 tegn, både små og store bogstaver, samt tal.</small>
                                </div>
                                <div class="field">
                                    <label for="global.companyCreate.data.user.passwordRepeat" class="is-sr-only">Gentag adgangskode *</label>
                                    <div class="control has-icons-right">
                                        <input id="global.companyCreate.data.user.passwordRepeat" class="input" type="password" placeholder="Gentag adgangskode *" required autocomplete="off"
                                            v-model="global.companyCreate.data.user.passwordRepeat"
                                            @input="validateStep()"
                                        >
                                        <span class="icon is-small is-right is-hidden" ref="passwordRepeatCheckmark">
                                            <i class="fas fa-check"></i>
                                        </span>
                                    </div>
                                </div>
                                
                            </div>

                        </div>

                    </div>
                    <div v-if="global.companyCreate.step == 2"  class="step step-2">
                        <h2>2: Dine kontaktoplysninger</h2>
                        <div class="company-fields">
                            <div class="field">
                                <label class="label" for="global.companyCreate.data.company.vat">CVR-nummer *</label>
                                <div class="control">
                                    <input id="global.companyCreate.data.company.vat" class="input" type="number" maxlength="8" placeholder="Tast CVR-nummer, og vi henter info fra cvr.dk" required
                                        v-model="global.companyCreate.data.company.vat"
                                        @input="handleVatChange($event)"
                                    >
                                    <p v-if="!global.companyCreate.data.company.vatAvailable" class="has-text-danger">En virksomhed med dette CVR-nummer er allerede registreret på medvirke.dk</p>
                                </div>
                            </div>
                            <div class="field">
                                <label class="label" for="global.companyCreate.data.company.name">Virksomhedens navn *</label>
                                <div class="control">
                                    <input id="global.companyCreate.data.company.name" class="input" type="text" required
                                        v-model="global.companyCreate.data.company.name"
                                        @change="validateStep()"
                                    >
                                </div>
                            </div>
                            <div class="field">
                                <label class="label" for="global.companyCreate.data.company.address">Adresse *</label>
                                <div class="control">
                                    <input id="global.companyCreate.data.company.address" class="input" type="text" required
                                        v-model="global.companyCreate.data.company.address"
                                        @change="validateStep()"
                                    >
                                </div>
                            </div>
                            <div class="field is-horizontal">
                                <div class="field-body">
                                    <div class="field">
                                        <label class="label" for="global.companyCreate.data.company.zip">Postnr. *</label>
                                        <input id="global.companyCreate.data.company.zip" class="input" type="number" minlength="4" maxlength="4" required
                                            v-model="global.companyCreate.data.company.zip"
                                            @change="validateStep()"
                                        >
                                    </div>
                                    <div class="field">
                                        <label class="label" for="global.companyCreate.data.company.city">By *</label>
                                        <input id="global.companyCreate.data.company.city" class="input" type="text" required
                                            v-model="global.companyCreate.data.company.city"
                                            @change="validateStep()"
                                        >
                                    </div>
                                </div>
                            </div>
                            <div class="field">
                                <label class="label" for="global.companyCreate.data.company.web">Hjemmeside </label>
                                <div class="control">
                                    <input id="global.companyCreate.data.company.web" class="input" type="text"
                                        v-model="global.companyCreate.data.company.web"
                                    >
                                </div>
                            </div>
                        </div>
                        <div class="contact-fields">
                            <div class="field">
                                <label class="label" for="global.companyCreate.data.contact.name">Kontaktperson *</label>
                                <div class="control">
                                    <input id="global.companyCreate.data.contact.name" class="input" type="text" placeholder="Kontaktpersons navn" required 
                                        v-model="global.companyCreate.data.contact.name"
                                        @change="validateStep()"
                                    >
                                </div>
                            </div>
                            <div class="field">
                                <label class="label" for="global.companyCreate.data.contact.email">E-mail *</label>
                                <div class="control">
                                    <input id="global.companyCreate.data.contact.email" class="input" type="text"  placeholder="Kontaktpersons e-mail" readonly disabled 
                                        v-model="global.companyCreate.data.user.email"
                                        @change="validateStep()"
                                    >
                                </div>
                            </div>
                            <div class="field">
                                <label class="label" for="global.companyCreate.data.contact.phone">Telefon</label>
                                <div class="control">
                                    <input id="global.companyCreate.data.contact.phone" class="input" type="text" placeholder="Kontaktpersons telefon"
                                        v-model="global.companyCreate.data.contact.phone"
                                        @change="validateStep()"
                                    >
                                </div>
                            </div>
                        </div>

                    </div>
                    <div v-if="global.companyCreate.step == 3"  class="step step-3">
                        <h2>3: Dine kompetencer</h2>
                        <div class="database-competences">
                            <p>Vælg op til 5 kompetencer, virksomhederne skal kunne søge dig på.</p>

                            <label for="competenceCategories" class="is-sr-only">Vælg kompetenceområde</label>
                            <select id="competenceCategories" @change="competenceCategoriesChange($event)" class="select mr-2" 
                                :disabled="global.companyCreate.data.company.competencesSelected.length >= 5">
                                <option value="">- Vælg kompetenceområde -</option>
                                <option v-for="(value, propertyName) in competenceCategories" :key="propertyName" :value="propertyName">{{ value.name }} ({{ Object.keys(value.competences).length }})</option>
                            </select>

                            <label for="competences" class="is-sr-only">Vælg kompetence</label>
                            <select id="competences" @change="competencesChange($event); validateStep()" class="select" :disabled="competences.length == 0 || global.companyCreate.data.company.competencesSelected.length >= 5">
                                <option value="">- Vælg kompetence -</option>
                                <option v-for="(value, propertyName) in competences" :key="propertyName" :value="propertyName">{{ value.name }}</option>
                            </select>

                            <ul class="competences-selected">
                                <li v-for="competence in global.companyCreate.data.company.competencesSelected" :key="competence" class="tag is-info mr-1 mt-1" @click="competenceRemove(competence); validateStep()" :title="['Fravælg kompetencen \'']+[competenceFromAlias(competence)]+['\'']">
                                    {{ competenceFromAlias(competence) }} 
                                    <button class="delete is-small"></button>
                                </li>
                            </ul>
                            <p v-if="global.companyCreate.data.company.competencesSelected.length >= 5">
                                Du har nu valgt 5 kompetencer. Hvis du ønsker at vælge andre end disse, skal du først vælge nogle fra. 
                            </p>

                        </div>
                        <div class="other-competences">
                            <h3>Valgfrie styrker</h3>
                            <p>
                                Angiv 3 dine styrker (max {{ global.companyCreate.data.company.otherCompetencesMaxLength }} tegn), der supplerer dine 5 kompetencer.<br>
                                <em>Bemærk: Virksomhederne kan ikke søge efter dine styrker, men de vises på din profil.</em>
                            </p>
                            
                            <div class="field">
                                <label class="label is-hidden" for="global.companyCreate.data.company.otherCompetences.one">Egen kompetence #1</label>
                                <div class="control">
                                    <input id="global.companyCreate.data.company.otherCompetences.one" :maxlength="global.companyCreate.data.company.otherCompetencesMaxLength" class="input" type="text" placeholder="Egen kompetence #1"
                                        v-model="global.companyCreate.data.company.otherCompetences.one"
                                    >
                                </div>
                            </div>
                            <div class="field">
                                <label class="label is-hidden" for="global.companyCreate.data.company.otherCompetences.two">Egen kompetence #2</label>
                                <div class="control">
                                    <input id="global.companyCreate.data.company.otherCompetences.two" :maxlength="global.companyCreate.data.company.otherCompetencesMaxLength" class="input" type="text" placeholder="Egen kompetence #2"
                                        v-model="global.companyCreate.data.company.otherCompetences.two"
                                    >
                                </div>
                            </div>
                            <div class="field">
                                <label class="label is-hidden" for="global.companyCreate.data.company.otherCompetences.three">Egen kompetence #3</label>
                                <div class="control">
                                    <input id="global.companyCreate.data.company.otherCompetences.three" :maxlength="global.companyCreate.data.company.otherCompetencesMaxLength" class="input" type="text" placeholder="Egen kompetence #3"
                                        v-model="global.companyCreate.data.company.otherCompetences.three"
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="global.companyCreate.step == 4"  class="step step-4">
                        <h2>4: Din pitch</h2>
                        <div class="field">
                            <label for="global.companyCreate.data.company.description" class="is-sr-only">Beskrivelse</label>
                            <textarea class="textarea" id="global.companyCreate.data.company.description" :placeholder="['Beskrivelse af dig og dine kompetencer. Max '] + global.descriptionMaxLength + [' tegn']" :maxlength="global.descriptionMaxLength"
                                v-model="global.companyCreate.data.company.description"
                                @keyup="validateStep(); onDescriptionChange()"
                            ></textarea>
                            <p class="has-text-right" :class="global.descriptionMaxLength - descriptionChars < 15 ? 'has-text-danger' : ''">{{ global.descriptionMaxLength - descriptionChars }} tegn tilbage</p>
                        </div>
                        <div class="field">
                            <label class="checkbox">
                                <input type="checkbox" v-model="global.companyCreate.data.user.permission"
                                > Ja, medvirke.dk må kontakte mig med relevante informationer
                            </label>
                        </div>
                        <div class="field">
                            <label class="checkbox">
                                <input type="checkbox" 
                                    v-model="global.companyCreate.termsAccepted"
                                    @change="validateStep()"
                                > Ja, jeg har læst og accepteret <a href="#" title="Åbner i nyt vindue" target="_blank">Forretningsbetingelser inkl. privatlivspolitik</a> for medvirke.dk
                            </label>
                        </div>
                    </div>
                    <div v-if="global.companyCreate.step == 5"  class="step step-5">
                        <h2>5: Betaling</h2>
                        <p>Informationerne på din Medvirker-profil er nu på plads.</p>
                        <p>Fortsæt til betaling så din profil kan blive aktiveret på medvirke.dk.</p>

                        <div class="promo">
                            <h3>Indtast evt. rabatkode</h3>
                            <div v-if="!global.companyCreate.promoCode.success" class="columns mb-0">
                                <div class="column mb-0 field">
                                    <label for="global.companyCreate.promoCode.code" class="is-sr-only">Rabatkode</label>
                                    <div class="control">
                                        <input id="global.companyCreate.promoCode.code" class="input" type="text" placeholder="Rabatkode" 
                                            v-model="global.companyCreate.promoCode.code"
                                        >
                                    </div>
                                </div>
                                <div class="column mb-0">
                                    <a v-if="global.companyCreate.promoCode.code" href="#" class="button" @click="promoCode()" >Anvend rabatkode</a>
                                </div>
                            </div>
                            <div v-if="global.companyCreate.promoCode.success">
                                <p>Rabatkoden er anvendt:</p>
                                <ul>
                                    <li>Normalpris: {{ global.companyCreate.priceNormal }} kr.</li>
                                    <li>Din rabat: {{ global.companyCreate.promoCode.amount }} kr.</li>
                                    <li>Din pris: <strong>{{ global.companyCreate.priceFinal }}</strong> kr. (+ moms {{ 0.25 * global.companyCreate.priceFinal }} kr.)</li>
                                </ul>
                            </div>
                            <p v-if="global.companyCreate.promoCode.success == false" class="has-text-danger">Rabatkoden findes desværre ikke eller er udløbet.</p>
                        </div>

                        

                        <p class="has-text-danger">...noget tekst, der fortæller, hvad der skal betales (600 kr. + moms)</p>
                    </div>
  
                </form>
                <button v-if="global.companyCreate.step > 1 && global.companyCreate.step < 6" class="button" @click="companyCreateNext(-1)">Tilbage</button>
                <button v-if="global.companyCreate.step < 6" class="button is-success" ref="btnNext" @click="companyCreateNext()" :disabled="!this.global.companyCreate.dataIsValid">
                    {{ global.companyCreate.step != 5 ? 'Næste' : 'Gå til betaling' }} 
                </button>
            </div>
            <div class="column">
                <h2>Din profil</h2>      
                <h3 v-if="global.companyCreate.data.company.name">{{ global.companyCreate.data.company.name }}</h3>
                <div class="content" v-html="global.companyCreate.data.company.description ? global.companyCreate.data.company.description.replaceAll('\n', '<br>') : ''"></div>

                <ul class="mt-4">
                    <li v-if="global.companyCreate.data.company.competencesSelected.length">
                        <h4 class="title is-6 mb-1">Kompetencer</h4>
                        <ul class="has-bullets">
                            <li v-for="competence in global.companyCreate.data.company.competencesSelected" :key="competence">
                                {{ competenceFromAlias(competence) }}
                            </li>
                        </ul>
                        <div v-if="Object.keys(global.companyCreate.data.company.otherCompetences).length">
                            <h4 class="title is-6 mt-4 mb-1">Styrker</h4>
                            <ul class="has-bullets">
                                <li v-for="(otherCompetence, key) in global.companyCreate.data.company.otherCompetences" :key="key">
                                    {{ otherCompetence }}
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li v-if="global.companyCreate.data.company.name" class="mt-4 has-text-weight-semibold">{{ global.companyCreate.data.company.name }}</li>
                    <li v-if="global.companyCreate.data.company.vat">CVR: {{ global.companyCreate.data.company.vat }}</li>
                    <li v-if="global.companyCreate.data.company.address">
                        {{ global.companyCreate.data.company.address }}<br>
                        {{ global.companyCreate.data.company.zip }} {{  global.companyCreate.data.company.city }}  
                    </li>
                    <li v-if="global.companyCreate.data.company.web">Web: <a :href="global.companyCreate.data.company.web" target="_blank">{{ global.companyCreate.data.company.web }}</a></li>
                    
                </ul>
                <div v-if="global.companyCreate.data.user.email" class="mt-4">
                    <h3 class="title is-6 mb-1">Kontaktperson</h3>
                    <ul>
                        <li v-if="global.companyCreate.data.contact.name">{{ global.companyCreate.data.contact.name }}</li>
                        <li v-if="global.companyCreate.data.contact.phone">Telefon: {{ global.companyCreate.data.contact.phone }}</li>
                        <li v-if="global.companyCreate.data.user.email">E-mail: {{ global.companyCreate.data.user.email }}</li>
                    </ul>   
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { useComposition } from '@/compositions/composition'
import ax from 'axios'
export const axios = ax

export default defineComponent({
    setup() {
        const { global } = useComposition();
        
        return {
            global
        }
    }, 
    data() {
        return {
            competenceCategories: [],
            competences: [],
            competencesSelected: [],
            descriptionChars: 0,
            apiRoot: process.env.VUE_APP_API_ROOT
        }
    },
    created() {
        this.competencesLoad();
    },
    mounted () {
        window.addEventListener('message', this.openOauthListen)
    },
    beforeDestroy () {
        window.removeEventListener('message', this.openOauthListen)
    },
  methods: {
    companyCreateNext(forward = 1) {
        let isPaymentStep = this.global.companyCreate.step == 5;
        if(isPaymentStep && forward > 0) {
            forward = 0;        // Do not go forward before payment is done
            this.submitData();
        }

        this.global.companyCreate.step += forward;
        this.$refs['btnNext'].disabled = forward > 0 ? true : false;
        this.validateStep(this.global.companyCreate.step);
    }, 
    validateStep() {
        var isValid = false;
        let user = this.global.companyCreate.data.user;

        switch(this.global.companyCreate.step) {
            case 1:      
                if(this.$refs['passwordCheckmark'] != undefined) {
                    if(user.password != undefined && isValidPassword(user.password)) {
                        this.$refs['passwordCheckmark'].classList.remove("is-hidden");
                    } else {
                        this.$refs['passwordCheckmark'].classList.add("is-hidden");
                    }
                }
                if(this.$refs['passwordRepeatCheckmark'] != undefined) {
                    if(
                        user.password != undefined && 
                        isValidPassword(user.password) &&
                        user.password == user.passwordRepeat
                    ) {
                        this.$refs['passwordRepeatCheckmark'].classList.remove("is-hidden");
                    } else {
                        this.$refs['passwordRepeatCheckmark'].classList.add("is-hidden");
                    }
                }               

                if(
                    (
                        this.global.companyCreate.data.user.emailAvailable && 
                        user.password != undefined && 
                        isValidPassword(user.password) &&
                        user.password == user.passwordRepeat
                    ) || 
                    this.global.companyCreate.data.user.providerType == 'sso'
                ) {
                    isValid = true;
                }
                break;


            case 2:
                // Start step 2 with creating email user from data from step 1
                if(this.global.companyCreate.data.user.providerType != 'sso' && !this.global.companyCreate.data.user.token) {
                    let url = process.env.VUE_APP_API_ROOT + '/user';
                    let payload = {
                        mode: 'create',
                        email: user.email,
                        password: user.password,
                    };

                    axios.post(url, payload, { withCredentials: true })
                        .then(response => {
                            this.global.companyCreate.data.user.providerType = 'email';
                            if(response.data.data.token) {
                                this.global.companyCreate.data.user.token = response.data.data.token;

                            }
                            console.log(response.data);
                        }) 
                } 

                let vatAvailable = this.global.companyCreate.data.company.vatAvailable;
                let companyVat = this.global.companyCreate.data.company.vat;
                let companyName = this.global.companyCreate.data.company.name;
                let companyAddress = this.global.companyCreate.data.company.address;
                let companyZip = this.global.companyCreate.data.company.zip;
                let companyCity = this.global.companyCreate.data.company.city;

                let contactName = this.global.companyCreate.data.contact.name;
                let contactEmail = this.global.companyCreate.data.contact.email;

                if(
                    vatAvailable && 
                    companyVat != undefined && 
                    companyVat.toString().length == 8 && 
                    companyName != '' &&
                    companyAddress != '' &&
                    companyZip != '' &&
                    companyCity != '' && 
                    contactName != '' && 
                    contactEmail != ''
                ) {
                    isValid = true;
                }
                break;

            case 3:
                let competencesSelected = this.global.companyCreate.data.company.competencesSelected;
                if(
                    competencesSelected.length > 0
                ) {
                    isValid = true;
                }
                break;

            case 4: 
                let description = this.global.companyCreate.data.company.description;
                let termsAccepted = this.global.companyCreate.termsAccepted;

                if(
                    description != undefined && 
                    description.length > 2 && description.length <= 500 && 
                    termsAccepted
                ) {
                    isValid = true;
                }
                break;

            case 5: 
                isValid = true;
                break;

        }
        this.$refs['btnNext'].disabled = isValid ? false : true;
    },
    isValidEmail(email) {
        if(isValidEmail(email)) {
            return true;
        } else {
            return false;
        }
    },
    isEmailAvailable() {
        let email = this.global.companyCreate.data.user.email;
        let validEmail = this.isValidEmail(email);
        let usesEmailProvider = this.global.companyCreate.data.user.providerType != 'sso';

        if(validEmail && usesEmailProvider) {
            
            let url = process.env.VUE_APP_API_ROOT + '/user';
            let payload = {
                email: email
            };

            axios.post(url, payload)
                .then(response => {            
                    if(response.data.isAvailable) {
                        this.global.companyCreate.data.user.emailAvailable = true;
                        this.global.companyCreate.data.user.token = ''; // Reset token, since a new email has been entered
                    } else {
                        this.global.companyCreate.data.user.emailAvailable = false;
                    }
                    this.validateStep();
                }) 
        } else {
            return true;
        }        
    },
    handleVatChange(e) {
            setTimeout(function(scope) { 
                // A little delay is necessary - otherwise e.target.value isn´t set          

                var vat = parseInt(e.target.value);

                // Check if company with this VAT number is already in database
                let url = process.env.VUE_APP_API_ROOT + '/company';
                let payload = {
                    vat: vat
                };

                axios.post(url, payload)
                    .then(response => {           
                        scope.global.companyCreate.data.company.vatAvailable = response.data.isAvailable;
                        scope.validateStep();
                        console.log(vat, response.data);
                    })

                if(vat.toString().length == 8) {
                    axios.get('https://cvrapi.dk/api?search=' + vat + '&country=dk')
                    .then(response => {
                        if(!response.data.hasOwnProperty('error')) {
                            // Always update company data (if user changes VAT, it makes sense to replace with new data from VAT API)
                            scope.global.companyCreate.data.company.name = response.data.name;
                            scope.global.companyCreate.data.company.address = response.data.address;
                            scope.global.companyCreate.data.company.zip = response.data.zipcode;
                            scope.global.companyCreate.data.company.city = response.data.city;
                            
                            // Only update if not empty (contact data is sometimes not related to that comming from VAT API)
                            var contactName = scope.global.companyCreate.data.contact.name;
                            if(response.data.owners != undefined && (contactName == '' || contactName == undefined)) {
                                scope.global.companyCreate.data.contact.name = response.data.owners[0].name;
                            }
                            var contactEmail = scope.global.companyCreate.data.contact.email;
                            if(contactEmail == '' || contactEmail == undefined) {
                                scope.global.companyCreate.data.contact.email = response.data.email;
                            }
                            var contactPhone = scope.global.companyCreate.data.contact.phone;
                            if(contactPhone == '' || contactPhone == undefined) {
                                scope.global.companyCreate.data.contact.phone = response.data.phone;
                            }
                            scope.validateStep();
                        }
                    })  
                }
            }, 200, this);
        },
        competencesLoad() {
            axios.get(process.env.VUE_APP_API_ROOT + '/competences')
                .then(response => {
                    this.competenceCategories = response.data;
                })   
        },
        competenceCategoriesChange(event) {
            var selectedCategory = this.competenceCategories[event.target.value];
            if(selectedCategory != undefined) {
                this.competenceCategorySelected = selectedCategory.id;
                this.competences = this.competenceCategories[event.target.value].competences;
            } else {
                this.competenceCategorySelected = null;
                this.competences = [];
            }
        },
        competencesChange(event) {
            if(event.target.value != '') {
                var competenceSelected = this.competenceCategorySelected + '-' + parseInt(event.target.value); 
                if(this.global.companyCreate.data.company.competencesSelected.includes(competenceSelected)) {
                    const index = this.global.companyCreate.data.company.competencesSelected.indexOf(competenceSelected);
                    if (index > -1) {
                        this.global.companyCreate.data.company.competencesSelected.splice(index, 1);
                    }
                } else {
                    this.global.companyCreate.data.company.competencesSelected.push(competenceSelected);
                }
            }
        },
        competenceFromAlias(alias, asObject = false) {
            var arrIds = alias.split('-');
            var competence = this.competenceCategories[arrIds[0]].competences[arrIds[1]];
            var object =  {
                    category: {
                        id: arrIds[0],
                        name: this.competenceCategories[arrIds[0]].name
                    },
                    competence: {
                        id: arrIds[1],
                        name: competence != undefined ? competence.name : ''
                    }
                };

            if(asObject) {
                return object;
            } else {
                return object.category.name + ' » ' + object.competence.name;
            }
        },
        competenceRemove(competence) {
            const index = this.global.companyCreate.data.company.competencesSelected.indexOf(competence);
            if (index > -1) {
                this.global.companyCreate.data.company.competencesSelected.splice(index, 1);
            }
        },
        onDescriptionChange() {
            this.descriptionChars = this.global.companyCreate.data.company.description != undefined ? this.global.companyCreate.data.company.description.length : 0;
        },
        submitData() {
            // Add spinner to submit button
            let spinnerE = document.createElement('i');
            spinnerE.innerHTML = '<i class="fa fa-spinner fa-spin ml-2"></i>';
            this.$refs['btnNext'].appendChild(spinnerE);

            let urlCompanyCreate = process.env.VUE_APP_API_ROOT + '/company';
            let payloadCompanyCreate = this.global.companyCreate.data;
            payloadCompanyCreate.mode = 'create';
            payloadCompanyCreate.promoCode = this.global.companyCreate.promoCode.code;
            payloadCompanyCreate.orderLines = [
                {
                    alias: 'subscription6months',
                    number: 1,
                    unit: 'pcs'
                }
            ];
            if(this.global.companyCreate.promoCode.amount) {
                payloadCompanyCreate.orderLines.push(
                    {
                        alias: 'discountPromoCode',
                        number: 1,
                        unit: 'pcs'
                    }
                );
                
            }
            console.log(payloadCompanyCreate);

            
            axios.post(urlCompanyCreate, payloadCompanyCreate)
            .then(response => {
                // Redirect to payment window
                let responseCompanyCreate = response.data;
                let paymentUrl = responseCompanyCreate.data.payment.payment.url;
                location.href = paymentUrl;
            })
            
        },
        openOauthListen(event) {
            // Thanks to: https://stackoverflow.com/questions/62689447/vuejs-recieve-event-from-iframe-using-postmessage
            console.log(event.data);
            
            if(event.data.hasOwnProperty('message')) {
                let message = JSON.parse(event.data.message);
                
                this.global.companyCreate.data.user.providerType = message.auth.providerType;
                this.global.companyCreate.data.user.provider = message.auth.provider;
                this.global.companyCreate.data.user.token = message.auth.token;
                if(message.auth.email) {
                    this.global.companyCreate.data.user.email = message.auth.email;
                    this.global.companyCreate.data.contact.email = message.auth.email;
                }
                if(message.auth.name) {
                    this.global.companyCreate.data.contact.name = message.auth.name;
                }

                this.validateStep();
            }
        },
        openOauth(provider) {   
            window.open(
                process.env.VUE_APP_API_ROOT + '/oauth/authenticate?provider=' + provider,
                '_blank', 
                'width=400, height=500'
            );    
        },
        promoCode() {
            let promoCode = this.global.companyCreate.promoCode.code;
            let url = process.env.VUE_APP_API_ROOT + '/promoCode';
            let payload = {
                promoCode: promoCode
            }

            axios.post(url, payload)
                .then(response => {
                    this.global.companyCreate.promoCode.success = response.data.success;
                    this.global.companyCreate.promoCode.amount = response.data.amount;
                    this.global.companyCreate.priceFinal = this.global.companyCreate.priceNormal - this.global.companyCreate.promoCode.amount;
                })
        }
  }
})
</script>