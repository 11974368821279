<template>
  <Header />
  <CreateCompany mode="foo" />
  <Footer/>
</template>

<script>
import { defineComponent } from 'vue'
import { useComposition } from '@/compositions/composition'

import Header from '@/components/Header.vue'
import CreateCompany from '@/components/Login/CreateCompany.vue'
import Footer from '@/components/Footer.vue'

export default defineComponent({
  setup() {
    const { global } = useComposition();
    
    return {
      global
    }
  },
  components: {
    Header,
    CreateCompany,
    Footer
  },
  created() {
    //this.global.mode.header = 'minimal';
  },
  computed: {
    currentRoute() {
        return this.$route;
    }
  }
})
</script>